import React from 'react'
import { IDeal, ITransaction } from '@lib/admin/types'
import { dateFormatter } from '@lib/admin/misc'
import { DoubleText } from '@components/admin/doubleText'

interface IDateCellProps {
  deal: IDeal
  transactions: ITransaction[]
  isoDate: string
  expand: () => void
}

export const DateCell: React.FC<IDateCellProps> = ({
  transactions,
  isoDate,
  expand,
}: IDateCellProps) => {
  let bottomText, href, onClick

  if (transactions.length === 1) {
    bottomText = transactions[0].processorId
    href = transactions[0].url
  } else if (transactions.length > 1) {
    bottomText = `${transactions.length} transactions`
    onClick = expand
  }

  return (
    <DoubleText
      top={dateFormatter.format(new Date(isoDate))}
      bottom={
        <a target='_blank' href={href} onClick={onClick} rel='noreferrer'>
          {bottomText}
        </a>
      }
    />
  )
}

export default DateCell
